import React, {Component} from "react";
import H1 from "../../atoms/headings/h1";
import Section from "../../atoms/Section/Section";
import H2 from "../../atoms/headings/h2";
import ProjectDescriptions from "../ProjectDescriptions/ProjectDescriptions";

class Portfolio extends Component {
    render() {
        let portfolio = this.props.nodeContent;

        return (
            <>
                <Section className={'main-section project-section'}>
                    <H1 className={'main-title'}>{portfolio.title}</H1>
                    <H2 className={'project-subtitle'}>{portfolio.field_subtitle}</H2>

                    <ProjectDescriptions
                        projectDescription={portfolio.body}
                        projectTags={portfolio.fieldTags}
                        images={portfolio.fieldMediaImage}
                        links={portfolio.fieldLink}
                    />
                </Section>
            </>
        )
    }
}

export default Portfolio;

