import React from "react";
import H3 from "../../atoms/headings/h3";
import './ProjectLinks.scss';
import { OutboundLink } from "gatsby-plugin-google-analytics"
import {FaExternalLinkSquareAlt} from "react-icons/all";

const ProjectLinks = (props) => {
    let key = 0;
    let links = props.links.map((link_element) => {

        let title = link_element.title;
        if (title.length === 0) {
            title = 'Bekijk het project online';
        }
        key++;
        return (
        <li key={key}>
            <OutboundLink href={link_element.uri.path} rel={'noreferrer noopener nofollow'} target={'_blank'}>
                <i className={'fa-icon'}><FaExternalLinkSquareAlt/></i> {title}
            </OutboundLink>
        </li>
        );

    });

    if (links.length > 0) {
        return (
            <>
                <H3>Project links</H3>
                <ul className={'project-links'}>
                    {links}
                </ul>
            </>
        )
    }
    return (
        <></>
    )
};

export default ProjectLinks;
