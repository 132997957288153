import React from "react";
import './ProjectDescriptions.scss';
import ProjectImages from "../../atoms/PortfolioImage/ProjectImages";
import Parser from 'html-react-parser';
import ProjectLinks from "../ProjectLinks/ProjectLinks";
import Button from "../../atoms/Buttons/Button";
import {trackCustomEvent} from "gatsby-plugin-google-analytics";
import {currentLanguageByPath, getTranslations} from "../../../helpers/MainHelper";

const ProjectDescriptions = (props) => {
    const i18next = getTranslations(currentLanguageByPath());
    let back_link = '/portfolio';
    if (currentLanguageByPath() === 'en') {
        back_link = '/en' + back_link;
    }

    return (
        <div className={'project-descriptions'}>
            <div className={'project-description project-general_description'}>
                {Parser(props.projectDescription)}

                <ProjectLinks
                    links={props.links}
                />

                <br />
                <Button
                    onClick={e => {
                        // Lets track that custom click
                        trackCustomEvent({
                            // string - required - The object that was interacted with (e.g.video)
                            category: "Back to portfolio",
                            // string - required - Type of interaction (e.g. 'play')
                            action: "click",
                        })
                        return true;
                    }}
                    link={back_link}
                    label={i18next.t('back_portfolio')}
                    class={'button-orange'}
                />
            </div>
            <div className={'project-description project-images'}>
                <ProjectImages
                    images={props.images}
                />
            </div>

        </div>
    );
};

export default ProjectDescriptions;
