import React from "react";
import Img from "gatsby-image";
import './ProjectImages.scss';


const ProjectImages = (props) => {
    let images = '';

    if (props.images !== undefined && props.images !== null && props.images.length > 0) {
        images = props.images.map((element) => {
            const image = element.gatsbyImageFile;
            return <Img fluid={image.childImageSharp.fluid} key={element.id}/>;
        });
    }
    return (
        <div className={'project-images'}>
            {images}
        </div>
    );
};

export default ProjectImages;
