import React from "react";
import Layout from "../components/templates/Layout";
import Portfolio from "../components/molecules/NodeTypes/portfolio";
import {graphql} from "gatsby";

const portfolio = ( {data} ) => (
    <Layout
        lang={data.drupal.portfolio.langcode}
        class={'portfolio'}
        activeMenu={'portfolio'}
        metatags={data.drupal.portfolio.url.metatags}
        activeNid={data.drupal.portfolio.nid}
        title={'Portfolio: ' + data.drupal.portfolio.title}
    >
        <Portfolio nodeContent={data.drupal.portfolio} />
    </Layout>
);

export default portfolio;

/**
 * @todo metatags / images
 */
export const query = graphql`
query portfolio($id: ID!, $language: Drupal_Langcode!) {
    drupal {
        portfolio: entityById(id: $id, langcode: $language, entityType: NODE) {
            ... on Drupal_NodePortfolio {
                nid
                title
                body 
                fieldSubtitle
                internalId: nid
                path {
                    alias
                }
                fieldTimeline {
                    value
                    endValue
                }
                fieldLink {
                    uri {
                        path
                    }
                    title
                }
                fieldTags {
                    id
                    label
                }
                fieldMediaImage {
                    ... on Drupal_MediaImage {
                        ...PortfolioMediaImage
                    }
                }
                url {
                    ... on Drupal_EntityUrl {
                        metatags {
                            tag
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }
                langcode
            }
        }
    }
}
`;

export const PortfolioMediaImage = graphql`
  fragment PortfolioMediaImage on Drupal_MediaImage {
    fieldMediaImage {
      alt
      entity {
        uri
      }
    }
    gatsbyImageFile {
      childImageSharp {
        fluid {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
